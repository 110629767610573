<script setup>
defineProps({
  title: {
    type: String,
    default: 'Unknown Error',
  },
  message: {
    type: String,
    default: 'Terjadi kesalahan',
  },
  textButton: {
    type: String,
    default: 'Kembali',
  },
  imageSrc: {
    type: String,
    default: '/images/error/408.png',
  },
})

const userCookies = useCookie('user')
</script>

<template>
  <div
    class="flex items-center justify-center h-screen text-[#1C314A] bg-white p-4"
  >
    <div class="flex flex-col justify-center items-center gap-9 w-11/12">
      <img :src="imageSrc" class="md:w-[418px] w-10/12 md:mb-6">
      <div class="flex flex-col justify-center items-center gap-4">
        <h1 class="text-2xl font-bold text-center w-full">
          {{ title }}
        </h1>
        <p class="text-sm text-center w-full">
          {{ message }}
        </p>
      </div>
      <NuxtLink
        :to="
          !userCookies ? '/login'
          : (userCookies?.lob.toUpperCase() === 'KMB' || userCookies?.lob === '') ? '/'
            : userCookies?.lob.toUpperCase() === 'WG' ? `/n${userCookies?.lob?.toLowerCase()}/dashboard`
              : `/${userCookies?.lob?.toLowerCase()}/dashboard`
        "
      >
        <KButton
          class="px-[10px] w-3/5 md:w-[418px]"
        >
          {{ textButton }}
        </KButton>
      </NuxtLink>
    </div>
  </div>
</template>
