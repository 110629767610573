import type { Response } from '@/type'

export const useAuth = defineStore({
  id: 'auth',
  state: () => ({
    loggedIn: false,
    user: <any>{},
    resetPasswordSuccess: false,
    branch: [],
    menu: {
      data: {
        menu: [],
      },
    },
  }),
  actions: {
    async login(credentials: { username: string; password: string }) {
      try {
        const response: Response = await $fetch('/api/auth/login', {
          method: 'POST',
          body: JSON.stringify({
            username: credentials.username,
            password: credentials.password,
          }),
        })

        this.loggedIn = true
        this.user = response.data

        const tokenCookie = useCookie('token')
        const userCookie = useCookie('user')

        delete response.data.menu

        tokenCookie.value = response.data.token
        userCookie.value = JSON.stringify(response.data)
        this.resetPasswordSuccess = false
      }
      catch (error: any) {
        return error.status
      }
    },
    async getBranchByUserId(id: number) {
      try {
        const response: any = await $fetch(`/api/auth/${id}`, {
          params: {
            token: useCookie('token').value,
          },
        })

        this.branch = response.data.branch
        localStorage.setItem('branch', JSON.stringify(this.branch))
        return true
      }
      catch (error: any) {
        return false
      }
    },
    mappingBranchId() {
      if (!this.isHeadOfficeBranch) {
        const arrBranchId: Array<number> = []
        this.branch.map((branch: any) => {
          return arrBranchId.push(branch.branch_id)
        })
        return arrBranchId.toString()
      }
      return ''
    },
    async forgotPassword(payload: string) {
      try {
        const response = await $fetch('/api/auth/forgot', {
          method: 'POST',
          body: JSON.stringify({
            email: payload,
          }),
        })

        return response
      }
      catch (error: any) {
        return error.status
      }
    },
    async checkToken() {
      useLoader().startLoading()
      try {
        const response: any = await $fetch('/api/auth/check', {
          params: {
            token: useCookie('token').value,
          },
        })

        this.menu = response
        useLoader().stopLoading()

        return response
      }
      catch (error: any) {
        useLoader().stopLoading()
        return error.status
      }
    },
    has(menu: string, privilege: string) {
      if (this.menu) {
        const checkMenu: any | undefined = this.menu?.data.menu.find((item: any) => item.menu_name === menu)
        if (checkMenu?.is_active) {
          const checkPrivilege: any | undefined = checkMenu.privilege.find((item: any) => item.privilege_name === privilege)
          return checkPrivilege?.is_cms
        }
        else { return false }
      }
    },
    logout() {
      const token = useCookie('token')
      const user = useCookie('user')
      if (typeof window !== 'undefined') {
        localStorage.removeItem('branch')
        localStorage.clear()
      }

      token.value = null
      user.value = null
      this.loggedIn = false
      this.user = {}
      this.branch = []
    },
  },
  getters: {
    isHeadOfficeBranch: (state) => {
      return state.user.branch_name.toUpperCase() === 'HEAD OFFICE'
    },
    isCurrentLOB: (state) => {
      return (userLOB: string): boolean => state.user.lob.toUpperCase() === userLOB
    },
    isSuperAdmin: (state) => {
      const userRole = state.user.role_name.toUpperCase()
      const userLob = state.user.lob.toUpperCase()
      const SUPER_ADMIN_ROLE = 'SUPER ADMIN'
      return userLob === '' && userRole === SUPER_ADMIN_ROLE
    },
  },
})
