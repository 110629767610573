import type { Error } from '@/type/error-config'

export default function useErrorConfig() {
  const data: Error[] = [
    {
      code: '400',
      title: 'Bad Request',
      message: 'Data yang Anda cari tidak dapat ditemukan, silakan kembali ke halaman sebelumnya',
      type: 'alert',
    },
    {
      code: '401',
      title: 'Unauthorized',
      message: 'Anda tidak memiliki hak akses, silakan kembali ke halaman sebelumnya',
      type: 'redirect',
    },
    {
      code: '403',
      title: 'Forbidden',
      message: 'Anda tidak memiliki akses untuk halaman ini',
      type: 'redirect',
    },
    {
      code: '404',
      title: 'Not Found',
      message: 'Halaman yang Anda cari tidak dapat ditemukan, silakan kembali ke halaman sebelumnya',
      type: 'redirect',
    },
    {
      code: '408',
      title: 'Request Timeout',
      message: 'Permintaan melebihi batas waktu. Harap ulang kembali.',
      type: 'alert',
      textButton: 'Ulangi Kembali',
    },
    {
      code: '500',
      title: 'Internal Server Error',
      message: 'Server kami sedang mengalami masalah. Silakan muat ulang atau kembali ke halaman sebelumnya.',
      type: 'redirect',
    },
  ]

  const redirect: Error[] = data.filter(item => item.type === 'redirect')

  const isRedirect = (code: string): Error | undefined => {
    return redirect.find((item: Error) => item.code === code)
  }

  const get = (code: string): Error | undefined => {
    return data.find(item => item.code === code)
  }

  return { data, redirect, isRedirect, get }
}
