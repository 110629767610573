<script setup>
import useErrorConfig from '@/utils/errorConfig'

const config = useRuntimeConfig()
const error = useErrorConfig().get('404')

if (config.public.env === 'development') {
  // show error detail only on development environment
  const errorDetail = useError().value
  error.code = '500'
  error.title = 'Internal Server Error'
  error.message = `${errorDetail.message} on ${errorDetail.url}`
}
</script>

<template>
  <BaseError
    :image-src="`/images/error/${error.code}.png`"
    :title="`Error ${error.code} - ${error.title}`"
    :message="error.message"
  />
</template>
