import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/middleware/auth.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "dashboard-kmb": () => import("/app/middleware/dashboard-kmb.ts"),
  "dashboard-kmob": () => import("/app/middleware/dashboard-kmob.ts"),
  "dashboard-nwg": () => import("/app/middleware/dashboard-nwg.ts"),
  dashboard: () => import("/app/middleware/dashboard.ts")
}